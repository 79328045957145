import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import withWidth from "@material-ui/core/withWidth";
import Button from "@material-ui/core/Button";
import MarketingSite from "../../../containers/MarketingSite/MarketingSite";
import Copyright from "../../../components/Copyright/Copyright";
import CustomTextField from "../../../components/CustomTextField/CustomTextField";
import Util from "../../../services/Util";
import constants from "../../../constants";
import axios from "../../../config/axios";

import "./password-reset.scss";

const SUCCESS_MUSICIAN_ICON = `${constants.PUBLIC_ASSETS_URL}/marketing-site/compressed/success-musician-graphic-min.svg`;

const EMAIL_VIEW_INSTRUCTION_MESSAGE =
  "Please enter your email address that you used to register. We'll send you an email with a link to reset your password. ";

const EMAIL_MESSAGES = {
  success: "A password reset link has been sent to your email address",
  notFound: "Sorry, the user at this email address could not be found",
  error: "Sorry, something went wrong, please try again",
  invalid: "The email entered is invalid, please try again",
  empty: "Email Address is empty, please try again",
};

const PASSWORD_MESSAGES = {
  success: "Your password was successfully reset",
  emptyField: "Please fill in all fields",
  notIdentical: "Passwords do not match, please try again",
  error: "Unable to reset password, please try again",
};

const PasswordReset = ({ location }) => {
  const token = new URLSearchParams(location.search).get("token") || "";
  const emailView = token === "";
  const [passwordView, setPasswordView] = useState(token !== "");
  const [successView, setSuccessView] = useState(false);
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const mounted = useRef(true);

  useEffect(() => {
    mounted.current = true;
    return () => {
      // On dismounting, set mounted to false
      mounted.current = false;
    };
  }, []);

  const submitEmail = async (ev) => {
    try {
      ev.preventDefault();

      if (!email) {
        setMessage(EMAIL_MESSAGES.empty);
        return;
      }

      const regex =
        /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!regex.test(email)) {
        setMessage(EMAIL_MESSAGES.invalid);
        return;
      }

      let response = await axios.post("/send-password-reset", {
        email,
      });

      if (mounted.current) {
        if (response.status === 200) {
          if (response.data.user) {
            setMessage(EMAIL_MESSAGES.success);
          } else {
            setMessage(EMAIL_MESSAGES.notFound);
          }
        } else {
          setMessage(EMAIL_MESSAGES.error);
        }
      }
    } catch (error) {
      if (mounted.current) {
        setMessage(EMAIL_MESSAGES.error);
      }
    }
  };

  const submitNewPassword = async (ev) => {
    try {
      ev.preventDefault();

      if (!newPassword || !confirmNewPassword) {
        setMessage(PASSWORD_MESSAGES.emptyField);
        return;
      }

      if (newPassword !== confirmNewPassword) {
        setMessage(PASSWORD_MESSAGES.notIdentical);
        return;
      }

      // General password validation
      const passwordValidationResult = Util.validatePassword(newPassword);
      if (passwordValidationResult) {
        setMessage(passwordValidationResult);
        return;
      }

      const response = await axios.post("/reset-password", {
        newPassword,
        token,
      });

      if (mounted.current) {
        if (response.status === 200) {
          setPasswordView(false);
          setSuccessView(true);
        } else {
          setMessage(PASSWORD_MESSAGES.error);
        }
      }
    } catch (error) {
      if (mounted.current) {
        setMessage(PASSWORD_MESSAGES.error);
      }
    }
  };

  return (
    <div
      className={"reset-page-wrapper"}
      style={{
        backgroundImage: `url(${constants.PUBLIC_ASSETS_URL}/landing-page/compressed/smooth-transparent-wave-min.svg), url(${constants.PUBLIC_ASSETS_URL}/landing-page/compressed/login-background-min.png)`,
      }}
    >
      <div className={"reset-page-container"}>
        <h1>Password Reset</h1>
        <form className={"reset-form"}>
          {passwordView || emailView ? (
            <div>
              {emailView && (
                <>
                  <p className={"instruction-message"}>
                    {EMAIL_VIEW_INSTRUCTION_MESSAGE}
                  </p>
                  <CustomTextField
                    label={"Email"}
                    type={"email"}
                    value={email}
                    setValue={setEmail}
                    placeholder={"Enter email address"}
                  />
                </>
              )}
              {passwordView && (
                <>
                  <CustomTextField
                    label={"New Password"}
                    type={"password"}
                    value={newPassword}
                    setValue={setNewPassword}
                    placeholder={"New Password *"}
                  />
                  <CustomTextField
                    label={"Confirm New Password"}
                    type={"password"}
                    value={confirmNewPassword}
                    setValue={setConfirmNewPassword}
                    placeholder={"Confirm New Password *"}
                  />
                </>
              )}
              <p className={"feedback-message"}>{message}</p>
              <Button
                className={"reset-button"}
                variant={"contained"}
                color={"primary"}
                onClick={passwordView ? submitNewPassword : submitEmail}
                type={"submit"}
              >
                Submit
              </Button>
            </div>
          ) : (
            successView && (
              <div>
                <img
                  src={SUCCESS_MUSICIAN_ICON}
                  alt={"Rocking guitarist pixel art"}
                />
                <p className={"instruction-message success"}>
                  {PASSWORD_MESSAGES.success}
                </p>
                <Link to="/login">
                  <Button
                    className={"reset-button"}
                    variant={"contained"}
                    color={"primary"}
                  >
                    Login
                  </Button>
                </Link>
              </div>
            )
          )}
        </form>
      </div>
      <Copyright />
    </div>
  );
};

export default MarketingSite(withWidth()(PasswordReset));
