const SPECIAL_CHARACTERS = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,./?~]/;
const ILLEGAL_CHARACTERS = /[<>]/;
const UPPER = /[A-Z]/;
const LOWER = /[a-z]/;
const NUMERIC = /\d/;

/**
 * Helper class with misc functions
 */
class Util {
  /**
   * Validates a given password given the following requirements:
   * at least 8 characters
   * at least one special character
   * at least one uppercase character
   * at least one lowercase character
   * at least one numeric character
   * no instances of characters '<' or '>'
   * @param {String} password the password to validate
   * @returns {(Boolean | String)}
   */
  static validatePassword = (password) => {
    let passwordMessage = [];

    if (password.length < 8) passwordMessage.push("8 characters");
    if (!SPECIAL_CHARACTERS.test(password))
      passwordMessage.push("a special character");
    if (!UPPER.test(password)) passwordMessage.push("an uppercase letter");
    if (!LOWER.test(password)) passwordMessage.push("a lowercase letter");
    if (!NUMERIC.test(password)) passwordMessage.push("a number");
    if (ILLEGAL_CHARACTERS.test(password))
      passwordMessage.push("no instances of '<' or '>'");

    if (passwordMessage.length > 0) {
      const last = `${
        passwordMessage.length > 1 ? " and " : ""
      }${passwordMessage.pop()}`;
      const message = passwordMessage.join(", ");

      return `Password requires ${message}${last}`;
    }

    return false; // return false to pass simple checker
  };
}

export default Util;
