import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Provider } from "react-redux";
import store from "./store";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import App from "./containers/App/App";
import * as serviceWorker from "./serviceWorker";
import "./index.scss";

const persistor = persistStore(store);
const history = createBrowserHistory();

// handles routing from s3 static website
// https://via.studio/journal/hosting-a-reactjs-app-with-routing-on-aws-s3
const app = document.getElementById("root");
if (app) {
  // set up the browser history with the updated location
  // (minus the # sign)
  const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
  if (path) {
    history.replace(path);
  }

  // render app
  ReactDOM.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>,
    app
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
