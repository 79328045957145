import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import DrawerNavigation from "../../routing/DrawerNavigation/DrawerNavigation";
import axios from "../../config/axios";

import "./dashboard-view.scss";

export default function DashboardView(ViewComponent) {
  return class extends Component {
    componentDidMount = () => {
      window.addEventListener("storage", async (event) => {
        if (event.newValue === "false" && event.key === "loggedIn") {
          try {
            if (!(await (await axios.get("/check-login")).data.authorized))
              throw new Error("Login credentials invalidated");
          } catch (err) {
            window.location.href = "/";
          }
        }
      });
    };

    render() {
      return (
        <div className={"dashboard-view-page"}>
          <DrawerNavigation />
          <Grid className={"dashboard-content-container"} container>
            <Grid className={"dashboard-content"} item xl={9} lg={10} xs={12}>
              <ViewComponent {...this.props} />
            </Grid>
          </Grid>
        </div>
      );
    }
  };
}
