import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import withWidth from "@material-ui/core/withWidth";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import CustomTextField from "../../../components/CustomTextField/CustomTextField";
import MarketingSite from "../../../containers/MarketingSite/MarketingSite";
import Copyright from "../../../components/Copyright/Copyright";
import constants from "../../../constants";
import axios from "../../../config/axios";
import Util from "../../../services/Util";
import styles from "../../../styles/constants.scss";

import "./register.scss";

const SUCCESS_MUSICIAN_ICON = `${constants.PUBLIC_ASSETS_URL}/marketing-site/compressed/success-musician-graphic-min.svg`;

const DEFAULT_ROLES = [
  "Manager",
  "Booking Agent",
  "Agent",
  "Lawyer",
  "Producer",
  "Artist",
  "Other",
];

const COMPLETED_REGISTRATION_MESSAGE =
  "Thank you for registering! You can now log in to the app.";

const ISSUE_MESSAGES = {
  failure: "Registration failed, please try again",
  invalid:
    "This registration link is invalid/expired. Please request a new one from your team administrator.",
  error: "An error has occurred, please try again",
  passwordsDiffer: "Passwords do not match, please try again",
  emptyField: "Please enter all required fields",
};

const INVALID_DATA_RESPONSE_CODES = [401, 403, 410];

const StyledMenuItem = withStyles({
  root: {
    color: styles.colorDarkPurpleTingedGrey,
  },
})(MenuItem);

const Register = ({ location }) => {
  const [firstName, setFirstName] = useState("");
  const [registerConfirmed, setRegisterConfirmed] = useState(false);
  const [lastName, setLastName] = useState("");
  const [alias, setAlias] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const userType =
    new URLSearchParams(location.search).get("user_type") || "non-admin";
  const adminTeamUser =
    new URLSearchParams(location.search).get("label_admin") === "true"
      ? true
      : false;

  const [role, setRole] = useState("");
  const [customRole, setCustomRole] = useState("");
  const token = new URLSearchParams(location.search).get("token") || "";
  const mounted = useRef(true);

  useEffect(() => {
    mounted.current = true;
    return () => {
      // On dismounting, set mounted to false
      mounted.current = false;
    };
  }, []);

  const register = async (e) => {
    try {
      e.preventDefault();

      if (
        firstName === "" ||
        password === "" ||
        passwordConfirmation === "" ||
        (role === "Other" && customRole === "")
      ) {
        setMessage(ISSUE_MESSAGES.emptyField);
        return;
      }

      if (password !== passwordConfirmation) {
        setMessage(ISSUE_MESSAGES.passwordsDiffer);
        return;
      }

      // General password validation
      const passwordValidationResult = Util.validatePassword(password);
      if (passwordValidationResult) {
        setMessage(passwordValidationResult);
        return;
      }

      const response = await axios.post("/register-user", {
        tokenValue: token,
        firstName: firstName,
        lastName: lastName,
        alias: alias,
        phone: phone,
        password: password,
        role:
          userType !== "admin" || !adminTeamUser
            ? role === "Other"
              ? customRole
              : role
            : "Admin",
        userType: userType,
        adminTeamUser: adminTeamUser,
      });

      if (mounted.current) {
        if (response.status === 200) {
          setRegisterConfirmed(true);
        } else {
          setMessage(ISSUE_MESSAGES.failure);
        }
      }
    } catch (error) {
      if (mounted.current) {
        if (INVALID_DATA_RESPONSE_CODES.includes(error.response.status)) {
          setMessage(ISSUE_MESSAGES.invalid);
        } else {
          setMessage(ISSUE_MESSAGES.error);
        }
      }
    }
  };

  return (
    <div
      className={"register-page-wrapper"}
      style={{
        backgroundImage: `url(${constants.PUBLIC_ASSETS_URL}/landing-page/compressed/smooth-transparent-wave-min.svg), url(${constants.PUBLIC_ASSETS_URL}/landing-page/compressed/login-background-min.png)`,
      }}
    >
      <div className={"register-page-container"}>
        {registerConfirmed ? (
          <div>
            <h1>Registration Complete</h1>
            <div className={"register-complete"}>
              <img
                src={SUCCESS_MUSICIAN_ICON}
                alt={"Rocking guitarist pixel art"}
              />
              <p className={"instruction-message success"}>
                Hi {alias ? alias : firstName} <br />
                {COMPLETED_REGISTRATION_MESSAGE}
              </p>
              <Link to="/login">
                <Button
                  className={"register-button"}
                  variant={"contained"}
                  color={"primary"}
                >
                  Login
                </Button>
              </Link>
            </div>
          </div>
        ) : (
          <form className={"register-form"} method={"post"} onSubmit={register}>
            <h1>Register</h1>
            <CustomTextField
              label={"First Name"}
              value={firstName}
              setValue={setFirstName}
              placeholder={"First Name *"}
              required={true}
              type={"text"}
            />
            <CustomTextField
              label={"Last Name"}
              value={lastName}
              setValue={setLastName}
            />
            {!adminTeamUser && (
              <CustomTextField
                label={"Preferred Nickname/Artist Name"}
                value={alias}
                setValue={setAlias}
              />
            )}
            <CustomTextField
              label={"Phone"}
              placeholder={"Phone Number"}
              value={phone}
              setValue={setPhone}
            />
            <CustomTextField
              type={"password"}
              label={"Password"}
              placeholder={"Password *"}
              value={password}
              setValue={setPassword}
              required={true}
            />
            <CustomTextField
              type={"password"}
              label={"Confirm Password"}
              placeholder={"Confirm Password *"}
              value={passwordConfirmation}
              setValue={setPasswordConfirmation}
              required={true}
            />
            {(userType !== "admin" || !adminTeamUser) && (
              <div>
                <h5 className={"role-select-label"}>{"Role"}:</h5>
                <Select
                  className={"role-select"}
                  disableUnderline={true}
                  displayEmpty={true}
                  name={"role"}
                  value={role}
                  onChange={(e) => {
                    setRole(e.target.value);
                  }}
                >
                  {/* The placeholder */}
                  {!role && (
                    <StyledMenuItem value="">Select your role</StyledMenuItem>
                  )}
                  {DEFAULT_ROLES.map((role) => {
                    return (
                      <StyledMenuItem value={role} key={role}>
                        {role}
                      </StyledMenuItem>
                    );
                  })}
                </Select>
              </div>
            )}
            {role === "Other" && (
              <CustomTextField
                className={"register-custom-role"}
                type={"text"}
                label={"Role"}
                placeholder={"Your custom role"}
                value={customRole}
                setValue={setCustomRole}
                required={true}
              />
            )}
            <p className={"instruction-message"}>{message}</p>
            <Button
              className={"register-button"}
              variant={"contained"}
              color={"primary"}
              type={"submit"}
            >
              Register
            </Button>
          </form>
        )}
      </div>
      <Copyright />
    </div>
  );
};

export default MarketingSite(withWidth()(Register));
