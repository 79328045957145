import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Collapse from "@material-ui/core/Collapse";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import CreateIcon from "@material-ui/icons/Create";
import Auth from "../../services/Auth";
import constants from "../../constants";
import axios from "../../config/axios";

import "./drawer-navigation.scss";

const BEATDAPP_IMAGE_LOGO = `${constants.PUBLIC_ASSETS_URL}/icons/logo/BeatdappMark-300dpi.png`;
const BEATDAPP_TEXT_LOGO = `${constants.PUBLIC_ASSETS_URL}/icons/logo/BeatdappType-300dpi.png`;
const HOME_ICON = `${constants.PUBLIC_ASSETS_URL}/icons/icon/home/white.svg`;
const LIBRARY_ICON = `${constants.PUBLIC_ASSETS_URL}/icons/icon/library/white.svg`;
const BLOCK_EXPLORER_ICON = `${constants.PUBLIC_ASSETS_URL}/icons/icon/blockexp/white.svg`;
const SONG_LIBRARY_ICON = `${constants.PUBLIC_ASSETS_URL}/icons/icon/song/white.svg`;
const ALBUM_LIBRARY_ICON = `${constants.PUBLIC_ASSETS_URL}/icons/icon/album/white.svg`;
const ARTIST_LIBRARY_ICON = `${constants.PUBLIC_ASSETS_URL}/icons/icon/artists/white.svg`;
const CHEVRON_ICON = `${constants.PUBLIC_ASSETS_URL}/icons/icon/chevron/white.svg`;
const SIGN_OUT_ICON = `${constants.PUBLIC_ASSETS_URL}/icons/icon/signout.svg`;
const ACCOUNT_ICON = `${constants.PUBLIC_ASSETS_URL}/icons/icon/account/white.svg`;

const DRAWER_WIDTH = 240;

const styles = (theme) => ({
  root: {
    display: "flex",
    position: "relative",
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  navIcon: {
    marginLeft: 6,
  },
  divider: {
    backgroundColor: "#FFFFFF",
    marginBottom: "8px",
    opacity: "50%",
  },
  drawerOpen: {
    background:
      "linear-gradient(175deg, rgba(119,46,221,1) 0%, rgba(237,39,132,1) 100%)",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
    width: DRAWER_WIDTH,
  },
  drawerClose: {
    background:
      "linear-gradient(175deg, rgba(119,46,221,1) 0%, rgba(237,39,132,1) 100%)",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    minHeight: "100vh",
    overflowX: "hidden",
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
    width: theme.spacing(8),
  },
  displayTextLogo: {
    display: "inline",
    verticalAlign: "super",
    width: "116px",
  },
  hideTextLogo: {
    display: "none",
    verticalAlign: "super",
    width: "0rem",
  },
  nested: {
    paddingLeft: "3rem",
  },
  listItem: {
    color: "#FFFFFF",
    whiteSpace: "nowrap",
  },
});

const CustomAccountIcon = () => {
  return (
    <div className={"account-icon-wrapper"}>
      <CreateIcon className={"create-icon"} fontSize={"small"}></CreateIcon>
      <img src={ACCOUNT_ICON} alt={"Account"} width={"20px"} />
    </div>
  );
};

class DrawerNavigation extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      subListLibrary: false,
      subListAccount: false,
      profileImage: "",
      username: "",
      accountType: "",
      profileImageLoaded: false,
      isAdmin: false,
      demo: false,
    };
  }

  componentDidMount = async () => {
    this._isMounted = true;

    try {
      const response = await axios.get("/components/user-info");
      if (response.status === 200 && this._isMounted) {
        this.setState({
          profileImage: response.data.profileImage,
          username: response.data.username,
          accountType: response.data.accountType,
          isAdmin: response.data.isAdmin,
          demo: response.data.demo,
        });
      }
    } catch (error) {
      this.setState({
        profileImage: "",
        username: "",
        accountType: "",
        isAdmin: false,
      });
    }
  };

  updateImage = async () => {
    try {
      const response = await axios.get("/components/user-info");
      if (response.status === 200 && this._isMounted) {
        this.setState({ profileImage: response.data.profileImage });
      }
      return true;
    } catch (error) {
      this.setState({ profileImage: "" });
      return false;
    }
  };

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({
      open: false,
      subListLibrary: false,
      subListAccount: false,
    });
  };

  toggleNested = (subList) => {
    this.setState({ [subList]: !this.state[subList] });
  };

  setLoaded = (boolean) => {
    this.setState({ profileImageLoaded: boolean });
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root + " drawer-navigation-wrapper"}>
        <CssBaseline />
        <Drawer
          classes={{
            paper: this.state.open ? classes.drawerOpen : classes.drawerClose,
          }}
          variant={"permanent"}
          onMouseOver={this.handleDrawerOpen}
          onMouseLeave={this.handleDrawerClose}
          onClick={this.state.open ? null : this.handleDrawerOpen}
          open={this.state.open}
        >
          <div className={"navigation-brand"}>
            <Link to={"/dashboard"}>
              <img
                className={"image-logo"}
                src={BEATDAPP_IMAGE_LOGO}
                alt={"Beatdapp icon"}
              />
              <img
                className={
                  this.state.open
                    ? classes.displayTextLogo
                    : classes.hideTextLogo
                }
                src={BEATDAPP_TEXT_LOGO}
                alt={"Beatdapp"}
              />
            </Link>
          </div>
          {this.state.open ? (
            <div className={"user-info-wrapper"}>
              {!this.state.profileImageLoaded ? (
                <img
                  className={"avatar-placeholder"}
                  src={ACCOUNT_ICON}
                  alt={"Placeholder"}
                />
              ) : null}
              <img
                className={"avatar"}
                src={this.state.profileImage}
                onError={(e) => {
                  if (!this.updateImage()) {
                    e.target.onerror = null;
                    e.target.src = ACCOUNT_ICON;
                    e.target.className = "avatar-placeholder";
                  }
                }}
                style={
                  !this.state.profileImageLoaded ? { visibility: "hidden" } : {}
                }
                onLoad={() => this.setLoaded(true)}
                alt={"Avatar"}
              />
              {this.state.username && (
                <div className={"username"}>{this.state.username}</div>
              )}
              {this.state.accountType && (
                <div className={"account-type"}>
                  <div className={"text"}>{this.state.accountType}</div>
                </div>
              )}
            </div>
          ) : null}
          <List className={this.state.open ? "" : "separator"}>
            <Link to={"/dashboard"}>
              <ListItem button>
                <ListItemIcon className={classes.navIcon}>
                  <img src={HOME_ICON} alt={"Home"} />
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.listItem }}
                  primary={"Home"}
                />
              </ListItem>
            </Link>
            <Link to={"/library"}>
              <ListItem button>
                <ListItemIcon className={classes.navIcon}>
                  <img src={LIBRARY_ICON} alt={"Library"} />
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.listItem }}
                  primary={"Library"}
                />
                <span
                  onClick={(event) => {
                    event.stopPropagation();
                    event.preventDefault();
                    this.toggleNested("subListLibrary");
                  }}
                >
                  <img
                    src={CHEVRON_ICON}
                    alt={"expand"}
                    className={
                      (this.state.subListLibrary
                        ? "open-chevron"
                        : "closed-chevron") + " chevron"
                    }
                  />
                </span>
              </ListItem>
            </Link>
            <Collapse
              in={this.state.subListLibrary}
              timeout={"auto"}
              unmountOnExit
            >
              <List component={"div"} disablePadding>
                <Link to={"/library/artists"}>
                  <ListItem className={classes.nested} button>
                    <ListItemIcon>
                      <img src={ARTIST_LIBRARY_ICON} alt={"Artists"} />
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: classes.listItem }}
                      primary={"Artists"}
                      className={"sublist-text"}
                    />
                  </ListItem>
                </Link>
                <Link to={"/library/albums"}>
                  <ListItem className={classes.nested} button>
                    <ListItemIcon>
                      <img src={ALBUM_LIBRARY_ICON} alt={"Albums"} />
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: classes.listItem }}
                      primary={"Albums"}
                      className={"sublist-text"}
                    />
                  </ListItem>
                </Link>
                <Link to={"/library/songs"}>
                  <ListItem className={classes.nested} button>
                    <ListItemIcon>
                      <img src={SONG_LIBRARY_ICON} alt={"Songs"} />
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: classes.listItem }}
                      primary={"Songs"}
                      className={"sublist-text"}
                    />
                  </ListItem>
                </Link>
              </List>
            </Collapse>
            {this.state.demo && (
              <Link to={"/block-explorer"}>
                <ListItem button>
                  <ListItemIcon className={classes.navIcon}>
                    <img src={BLOCK_EXPLORER_ICON} alt={"Block Explorer"} />
                  </ListItemIcon>
                  <ListItemText
                    classes={{ primary: classes.listItem }}
                    primary={"Block Explorer"}
                  />
                </ListItem>
              </Link>
            )}
          </List>
          <Divider className={classes.divider} variant={"middle"} />
          <Link to={"/profile"}>
            <ListItem button>
              <ListItemIcon className={classes.navIcon}>
                <img src={ACCOUNT_ICON} alt={"Account"} />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.listItem }}
                primary={"Your Account"}
              />
              <span
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  this.toggleNested("subListAccount");
                }}
              >
                <img
                  src={CHEVRON_ICON}
                  alt={"expand"}
                  className={
                    (this.state.subListAccount
                      ? "open-chevron"
                      : "closed-chevron") + " chevron"
                  }
                />
              </span>
            </ListItem>
          </Link>
          <Collapse
            in={this.state.subListAccount}
            timeout={"auto"}
            unmountOnExit
          >
            <List component={"div"} disablePadding>
              <Link to={"/users"}>
                <ListItem className={classes.nested} button>
                  <ListItemIcon>
                    <CustomAccountIcon />
                  </ListItemIcon>
                  <ListItemText
                    classes={{ primary: classes.listItem }}
                    primary={
                      this.state.isAdmin ? "Manage Accounts" : "View Team"
                    }
                    className={"sublist-text"}
                  />
                </ListItem>
              </Link>
              <Link to="/profile">
                <ListItem className={classes.nested} button>
                  <ListItemIcon>
                    <CustomAccountIcon />
                  </ListItemIcon>
                  <ListItemText
                    classes={{ primary: classes.listItem }}
                    primary={"Profile"}
                    className={"sublist-text"}
                  />
                </ListItem>
              </Link>
            </List>
          </Collapse>

          <ListItem button onClick={Auth.signOut}>
            <ListItemIcon className={classes.navIcon}>
              <img src={SIGN_OUT_ICON} alt={"Sign Out"} />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItem }}
              primary={"Sign Out"}
            />
          </ListItem>
        </Drawer>
      </div>
    );
  }
}

export default withStyles(styles)(DrawerNavigation);
