import { handleActions } from "redux-actions";
import countries from "../constants/countries.json";
import { UPDATE_FILTER } from "../constants/redux-actions";

// setup default country filter
let defaultContinentCountries = {};

Object.keys(countries).forEach((continent) => {
  defaultContinentCountries[continent] = [];
});

const defaultState = {
  currentFilter: defaultContinentCountries,
};

const filterReducer = handleActions(
  {
    [UPDATE_FILTER]: (state, action) => ({
      ...state,
      currentFilter: action.payload,
    }),
  },
  defaultState
);

export default filterReducer;
