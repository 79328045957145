const appConstants = {
  PUBLIC_ASSETS_URL: "https://cdn1.beatdapp.com",
  STAGING_MARKETING_SITE_URL: "https://staging.beatdapp.com",
  PRODUCTION_MARKETING_SITE_URL: "https://beatdapp.com",
  DATE_RANGES: [
    "Today",
    "Last Week",
    "Last Month",
    "Last Quarter",
    "Last Year",
    "Custom",
  ],
  DEMO_DEFAULT_SELECTED_PLATFORMS: ["SPO", "APL", "AMZ"],
};

export default appConstants;
