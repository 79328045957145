import React, { useEffect, useState } from "react";
import LoggedInNotFound from "../../../components/LoggedInNotFound/LoggedInNotFound";
import NotLoggedInNotFound from "../../../components/NotLoggedInNotFound/NotLoggedInNotFound";
import axios from "../../../config/axios";

const NotFound = (props) => {
  const [authenticated, setAuthenticated] = useState(undefined);

  useEffect(() => {
    const getAuthentication = async () => {
      try {
        const response = await axios.get("/authenticate");
        setAuthenticated(response.status === 200);
      } catch (error) {
        setAuthenticated(false);
      }
    };

    getAuthentication();
  }, []);

  if (authenticated !== undefined) {
    return authenticated ? (
      <LoggedInNotFound {...props} />
    ) : (
      <NotLoggedInNotFound {...props} />
    );
  } else {
    // Keep from throwing error while awaiting the authenticate response
    return <div></div>;
  }
};

export default NotFound;
