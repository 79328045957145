import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { taskMiddleware } from "react-palm/tasks";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import filterReducer from "./reducers/filterReducer";

const reducers = combineReducers({
  filter: filterReducer,
});

const persistConfig = {
  key: "root",
  whitelist: ["filter"],
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  devTools: !process.env.REACT_APP_STAGE === "production",
  middleware: [taskMiddleware],
});

export default store;
