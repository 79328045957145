import React from "react";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import styles from "../../styles/constants.scss";

import "./custom-text-field.scss";

const CustomTextFieldStyles = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      background: styles.colorWhite,
      color: styles.colorDarkPurpleTingedGrey,
      "& fieldset": {
        borderColor: styles.colorPurpleTingedGrey,
      },
      "&:hover fieldset": {
        borderColor: styles.colorPurpleTingedGrey,
      },
      "&.Mui-focused fieldset": {
        borderColor: styles.colorPurpleTingedGrey,
      },
    },
  },
})(TextField);

/**
 * Generates a text field for a form
 * @param {String} label The label for the text field
 * @param {String} type The type/name of the text field
 * @param {String} value The value, saved in state, of the text field
 * @param {Function} setValue The function to set in state the value of the text field
 * @param {String} placeholder Optional placeholder text field, defaults to label
 * @param {String} className Optional class name for the text field
 */
const CustomTextField = ({
  label,
  type,
  value,
  setValue,
  placeholder = "",
  className = "",
}) => {
  return (
    <label className={"custom-input-label"} htmlFor={type}>
      <h5>{label}:</h5>
      <CustomTextFieldStyles
        className={className}
        variant={"outlined"}
        type={type}
        name={type}
        placeholder={placeholder ? placeholder : label}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        fullWidth
        autoComplete={"on"}
      />
    </label>
  );
};

export default CustomTextField;
