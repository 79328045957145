import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import axios from "../../config/axios";

import MarketingNavigation from "../../routing/MarketingNavigation/MarketingNavigation";

export default function MarketingSite(ViewComponent) {
  return class extends Component {
    _isMounted = false;

    constructor(props) {
      super(props);
      this.state = {
        authenticated: false,
      };
    }

    async componentDidMount() {
      this._isMounted = true;

      try {
        const response = await axios.get("/check-login");

        if (this._isMounted) {
          this.setState({
            authenticated: response.status === 200 && response.data.authorized,
          });
        }
      } catch (error) {
        this.setState({ authenticated: false });
      }
    }

    componentWillUnmount() {
      this._isMounted = false;
    }

    render() {
      // redirect user if already authenticated
      if (this.state.authenticated) {
        return (
          <Redirect
            to={{
              pathname: "/dashboard",
            }}
          />
        );
      }

      return (
        <div className={"marketing-site-page"}>
          <MarketingNavigation />
          <ViewComponent {...this.props} />
        </div>
      );
    }
  };
}
