import React from "react";
import DashboardView from "../../containers/DashboardView/DashboardView";
import "./logged-in-not-found.scss";

const LoggedInNotFound = () => {
  return (
    <div className={"not-found-page-logged"}>
      <div className={"not-found-content-wrapper"}>
        <span className={"code"}>404</span>
        <br />
        <span className={"not-found"}>Page not found</span>
      </div>
    </div>
  );
};

export default DashboardView(LoggedInNotFound);
