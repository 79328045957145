import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import withWidth from "@material-ui/core/withWidth";
import Button from "@material-ui/core/Button";
import MarketingSite from "../../../containers/MarketingSite/MarketingSite";
import Copyright from "../../../components/Copyright/Copyright";
import CustomTextField from "../../../components/CustomTextField/CustomTextField";
import store from "../../../store";
import countries from "../../../constants/countries.json";
import { UPDATE_FILTER } from "../../../constants/redux-actions";
import constants from "../../../constants";
import axios from "../../../config/axios";

import "./login.scss";

const LOGIN_CTA_BUTTON_TEXT = "Login";
const FORGOT_PASSWORD_LINK_TEXT = "Forgot Password";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [redirectAuthenticated, setRedirectAuthenticated] = useState(false);

  /**
   * Initializes the user session regional filters
   * @param {Object} userFilterState the user's last filter state
   */
  const setUserRegionFilter = (userFilterState) => {
    if (!userFilterState) {
      // setup default country filter
      let defaultContinentCountries = {};

      Object.keys(countries).forEach((continent) => {
        defaultContinentCountries[continent] = [];
      });
      store.dispatch({
        type: UPDATE_FILTER,
        payload: defaultContinentCountries,
      });
    } else {
      // use user's last filter state
      store.dispatch({ type: UPDATE_FILTER, payload: userFilterState });
    }
  };

  const login = async (ev) => {
    ev.preventDefault();

    const response = await axios
      .post("/login", {
        email,
        password,
      })
      .catch((error) => {
        setMessage(
          error?.response?.data?.message
            ? error.response.data.message
            : "Something went wrong, please try again."
        );
      });

    if (response?.status === 200 && response?.data?.message === "OK") {
      // store filter state in redux
      setUserRegionFilter(response?.data?.filter);

      window.localStorage.setItem("loggedIn", true);
      setRedirectAuthenticated(true);
    }
  };

  return redirectAuthenticated ? (
    <Redirect
      to={{
        pathname: "/dashboard",
      }}
    />
  ) : (
    <div
      className={"login-page-wrapper"}
      style={{
        backgroundImage: `url(${constants.PUBLIC_ASSETS_URL}/landing-page/compressed/smooth-transparent-wave-min.svg), url(${constants.PUBLIC_ASSETS_URL}/landing-page/compressed/login-background-min.png)`,
      }}
    >
      <div className={"login-page-container"}>
        <h1>Login</h1>
        <form className={"login-form"} method={"post"} onSubmit={login}>
          <CustomTextField
            label={"Email"}
            type={"email"}
            value={email}
            setValue={setEmail}
            placeholder={"Enter email address"}
          />
          <CustomTextField
            label={"Password"}
            type={"password"}
            value={password}
            setValue={setPassword}
            placeholder={"Enter password"}
          />
          <div className={"forgot-password-wrapper"}>
            <Link to={"/password-reset"}>
              <span className={"forgot-password-link"}>
                {FORGOT_PASSWORD_LINK_TEXT}
              </span>
            </Link>
          </div>
          <p className={"login-message"}>{message}</p>
          <Button
            className={"login-button"}
            variant={"contained"}
            color={"primary"}
            type={"submit"}
          >
            {LOGIN_CTA_BUTTON_TEXT}
          </Button>
        </form>
      </div>
      <Copyright />
    </div>
  );
};

export default MarketingSite(withWidth()(Login));
