import React from "react";
import { useHistory } from "react-router-dom";
import MarketingSite from "../../containers/MarketingSite/MarketingSite";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import constants from "../../constants";
import styles from "../../styles/constants.scss";

import "./not-logged-in-not-found.scss";

const GRAPHIC_IMAGE = {
  src: `${constants.PUBLIC_ASSETS_URL}/marketing-site/compressed/404-graphic-min.svg`,
  alt: "404 Page Graphic",
};

const NOT_FOUND_HEADER = {
  mobile: "This page has left the building.",
  desktop: "PAGE NOT FOUND",
};

const NOT_FOUND_MESSAGE =
  "The page you are looking for doesn't exist or an error occurred.";

const CTA_BUTTON_TEXT = "Go Back";

const NotLoggedInNotFound = () => {
  const history = useHistory();
  const useMobileView = useMediaQuery(
    `(max-width: ${styles.smMobileBreakpoint})`
  );

  const returnToLogin = () => {
    // Redirects user to the to login page
    // Since we know they are not authenticated/logged in
    // And assume they would not want to navigate away from the app
    history.push("/login");
  };

  return (
    <div className={"not-found-page-not-logged"}>
      <img
        className={"not-found-graphic-image"}
        src={GRAPHIC_IMAGE.src}
        alt={GRAPHIC_IMAGE.alt}
      />
      <h3 className={"not-found-header"}>
        {NOT_FOUND_HEADER[useMobileView ? "mobile" : "desktop"]}
      </h3>
      <div className={"not-found-message"}>{NOT_FOUND_MESSAGE}</div>
      <div className={"go-back-button"}>
        <button onClick={returnToLogin}>{CTA_BUTTON_TEXT}</button>
      </div>
    </div>
  );
};

export default MarketingSite(NotLoggedInNotFound);
