import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import AsyncComponent from "./AsyncComponent/AsyncComponent";

// marketing site views
import Login from "../views/dashboard/Login/Login";
import PasswordReset from "../views/dashboard/PasswordReset/PasswordReset";
import Register from "../views/dashboard/Register/Register";

// misc. views
import NotFound from "../views/misc/NotFound/NotFound";

// dashboard views
const PrivateRoute = AsyncComponent(() =>
  import("../routing/PrivateRoute/PrivateRoute")
);
const Dashboard = AsyncComponent(() =>
  import("../views/dashboard/Dashboard/Dashboard")
);
const LibraryHome = AsyncComponent(() =>
  import("../views/dashboard/LibraryHome/LibraryHome")
);
const Library = AsyncComponent(() =>
  import("../views/dashboard/Library/Library")
);
const Song = AsyncComponent(() => import("../views/dashboard/Song/Song"));
const Album = AsyncComponent(() => import("../views/dashboard/Album/Album"));
const Artist = AsyncComponent(() => import("../views/dashboard/Artist/Artist"));
const BlockExplorer = AsyncComponent(() =>
  import("../views/dashboard/BlockExplorer/BlockExplorer")
);
const Users = AsyncComponent(() => import("../views/dashboard/Users/Users"));
const Profile = AsyncComponent(() =>
  import("../views/dashboard/Profile/Profile")
);

/**
 * Handles application routes.
 */
const Router = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path={"/"}>
          <Redirect to="/login" />
        </Route>
        <Route path={"/login"} component={Login} />
        <Route path={"/password-reset"} component={PasswordReset} />
        <Route path={"/register"} component={Register} />
        <PrivateRoute path={"/dashboard"} component={Dashboard} />
        <PrivateRoute exact path={"/library"} component={LibraryHome} />
        <Route path={"/library/:tab"} component={Library} />
        <PrivateRoute path={"/songs/:songIdentifier"} component={Song} />
        <PrivateRoute path={"/albums/:albumIdentifier"} component={Album} />
        <PrivateRoute path={"/artists/:artistIdentifier"} component={Artist} />
        <PrivateRoute
          path={"/block-explorer/:songIdentifier?"}
          component={BlockExplorer}
        />
        <PrivateRoute path={"/users"} component={Users} />
        <PrivateRoute path={"/profile"} component={Profile} />
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
