import React from "react";
import { withStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { useTheme, useMediaQuery } from "@material-ui/core";
import SocialIcon from "../../components/SocialIcon/SocialIcon";
import HeaderLinkMobile from "../../components/HeaderLinkMobile/HeaderLinkMobile";
import styles from "../../styles/constants.scss";
import constants from "../../constants";
import "./marketing-drawer.scss";

// social media icons
const TWITTER_ICON = `${constants.PUBLIC_ASSETS_URL}/icons/icon/landing-page/twitter_alt.svg`;
const LINKEDIN_ICON = `${constants.PUBLIC_ASSETS_URL}/icons/icon/landing-page/linkedin_alt.svg`;
const CRUNCHBASE_ICON = `${constants.PUBLIC_ASSETS_URL}/icons/icon/landing-page/crunchbase_alt.svg`;
const TWITTER_HOVER_ICON = `${constants.PUBLIC_ASSETS_URL}/icons/icon/landing-page/twitter_alt_hover.svg`;
const LINKEDIN_HOVER_ICON = `${constants.PUBLIC_ASSETS_URL}/icons/icon/landing-page/linkedin_alt_hover.svg`;
const CRUNCHBASE_HOVER_ICON = `${constants.PUBLIC_ASSETS_URL}/icons/icon/landing-page/crunchbase_alt_hover.svg`;

// social media links
const BLOG_LINK = "https://blog.beatdapp.com";
const TWITTER_LINK = "https://twitter.com/beatdapp";
const LINKEDIN_LINK = "https://linkedin.com/company/beatdapp";
const CRUNCHBASE_LINK = "https://www.crunchbase.com/organization/beatdapp";

// marketing site url
const MARKETING_SITE =
  process.env.REACT_APP_STAGE === "production"
    ? constants.PRODUCTION_MARKETING_SITE_URL
    : constants.STAGING_MARKETING_SITE_URL;

const overrideStyles = {
  drawerList: {
    backgroundColor: styles.colorDarkPurple,
    width: "auto",
    marginTop: "84px",
  },
  closeIcon: {
    color: "white",
    width: "30px",
    height: "30px",
  },
};

const MarketingDrawer = (props) => {
  let page;
  if (window.location.pathname.includes("login")) page = "login";

  const ShouldHideDrawer = () => {
    const theme = useTheme();
    const hideDrawer = useMediaQuery(theme.breakpoints.up("md"));

    if (props.isOpen && props.isMounted && hideDrawer) {
      // Prevents Issue where we'd try to update the state while MUI re-renders the component
      setTimeout(() => {
        props.toggleDrawer(!props.isOpen);
      }, 1);
    }
  };

  window.addEventListener("resize", ShouldHideDrawer());

  return (
    <SwipeableDrawer
      anchor={"top"}
      open={props.isOpen}
      onClose={() => {
        props.toggleDrawer(false);
      }}
      onOpen={() => {
        props.toggleDrawer(true);
      }}
      style={{ zIndex: 1 }}
    >
      <List
        className={props.classes.drawerList + " marketing-drawer-wrapper"}
        onClick={() => {
          props.toggleDrawer(false);
        }}
      >
        <HeaderLinkMobile
          href={MARKETING_SITE + "/dsps-otts"}
          text={"DSPs & OTTs"}
        />
        <HeaderLinkMobile href={MARKETING_SITE + "/labels"} text={"Labels"} />
        <HeaderLinkMobile href={BLOG_LINK} text={"Blog"} />
        <HeaderLinkMobile href={MARKETING_SITE + "/press"} text={"Press"} />

        <a href={MARKETING_SITE + "/request-demo"}>
          <ListItem button className={"request-demo-button"}>
            <span className={"request-demo-option"}>
              <ListItemText
                className={"list-item-label"}
                primary={"Request a Demo"}
              />
            </span>
          </ListItem>
        </a>
        <HeaderLinkMobile
          href={MARKETING_SITE + "/fraud-checker"}
          text={"Fraud Checker"}
        />
        <HeaderLinkMobile
          link={"/login"}
          text={"Login"}
          isActive={page === "login"}
        />
        <HeaderLinkMobile
          href={MARKETING_SITE + "/docs"}
          text={"Onboarding Documentation"}
        />
        <ListItem className={"socials"}>
          <div className={"social-media-icon"}>
            <SocialIcon
              icon={TWITTER_ICON}
              hover={TWITTER_HOVER_ICON}
              link={TWITTER_LINK}
              alt={"Twitter"}
            />
          </div>
          <div className={"social-media-icon"}>
            <SocialIcon
              icon={LINKEDIN_ICON}
              hover={LINKEDIN_HOVER_ICON}
              link={LINKEDIN_LINK}
              alt={"LinkedIn"}
            />
          </div>
          <div className={"social-media-icon"}>
            <SocialIcon
              icon={CRUNCHBASE_ICON}
              hover={CRUNCHBASE_HOVER_ICON}
              link={CRUNCHBASE_LINK}
              alt={"Crunchbase"}
            />
          </div>
        </ListItem>
      </List>
    </SwipeableDrawer>
  );
};

export default withStyles(overrideStyles)(MarketingDrawer);
