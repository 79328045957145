import React from "react";
import constants from "../../constants";
import "./copyright.scss";

const MARKETING_SITE =
  process.env.REACT_APP_STAGE === "production"
    ? constants.PRODUCTION_MARKETING_SITE_URL
    : constants.STAGING_MARKETING_SITE_URL;

const PRIVACY_POLICY_LINK_TEXT = "Privacy Policy";
const TERMS_OF_USE_LINK_TEXT = "Terms of Use";

const Copyright = () => {
  const date = new Date();
  return (
    <div className={"copyright-wrapper"}>
      <div className={"copyright"}>
        <span className={"copyright-text"}>
          &copy; {date.getFullYear()} Beatdapp Software, Inc.
        </span>
        <span className={"copyright-text"}>All Rights Reserved.</span>
        <span className={"copyright-text"}>
          Services not endorsed by DDEX&reg;
        </span>
        <span className={"copyright-text"}>
          <a href={MARKETING_SITE + "/privacy"}>{PRIVACY_POLICY_LINK_TEXT}</a>
        </span>
        <span className={"copyright-text"}>
          <a href={MARKETING_SITE + "/terms"}>{TERMS_OF_USE_LINK_TEXT}</a>
        </span>
      </div>
    </div>
  );
};

export default Copyright;
