import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import MarketingDrawer from "../MarketingDrawer/MarketingDrawer";
import HeaderLink from "../../components/HeaderLink/HeaderLink";
import styles from "../../styles/constants.scss";
import constants from "../../constants";
import "./marketing-navigation.scss";

const TEXT_LOGO = `${constants.PUBLIC_ASSETS_URL}/icons/logo/BeatdappType.svg`;
const IMAGE_LOGO = `${constants.PUBLIC_ASSETS_URL}/icons/logo/BeatdappMark.svg`;

const HAMBURGER_MENU_ICON = `${constants.PUBLIC_ASSETS_URL}/icons/icon/landing-page/hamburgermenu_alt.svg`;
const CLOSE_MENU_ICON = `${constants.PUBLIC_ASSETS_URL}/icons/icon/landing-page/close.svg`;
const BLOG_LINK = "https://blog.beatdapp.com";

// marketing site url
const MARKETING_SITE =
  process.env.REACT_APP_STAGE === "production"
    ? constants.PRODUCTION_MARKETING_SITE_URL
    : constants.STAGING_MARKETING_SITE_URL;

const overrideStyles = () => ({
  root: {
    position: "absolute",
    background: styles.colorDarkPurple,
    boxShadow: "none",
    // eslint-disable-line no-useless-computed-key
    [`@media (min-width: ${styles.mdMobileBreakpoint})`]: {
      "& .MuiToolbar-gutters": {
        paddingLeft: "40px",
        paddingRight: "40px",
      },
      height: styles.headerHeight,
    },
    // eslint-disable-line no-useless-computed-key
    [`@media (max-width: ${styles.mdMobileBreakpoint})`]: {
      "& .MuiToolbar-gutters": {
        paddingLeft: "16px",
        paddingRight: "16px",
      },
      height: styles.mobileHeaderHeight,
      position: "fixed",
    },
  },
  loginButton: {
    textTransform: "none",
    color: styles.colorNavGrey,
    fontWeight: styles.fontBoldFontWeight,
    fontSize: styles.fontLargeFontSize,
    lineHeight: styles.fontDefaultLineHeight,
    border: "none",
    marginRight: "64px",
    transition: "all ease 0.4s",
    textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    "&:hover": {
      color: "white",
    },
    padding: "5px 15px",
  },
});

class MarketingNavigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drawerOpen: false,
      isMounted: false,
    };
  }

  componentDidMount() {
    this.setState({ isMounted: true });
  }

  componentWillUnmount() {
    this.setState({ isMounted: false });
  }

  openDrawer = (isOpen) => {
    this.setState({ drawerOpen: isOpen });
  };

  render() {
    let { classes } = this.props;

    let page;
    if (window.location.pathname.includes("login")) {
      page = "login";
    }

    return (
      <AppBar className={`${classes.root} navigation-wrapper`}>
        <Toolbar>
          <Grid
            container
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Grid
              container
              item
              justifyContent={"flex-start"}
              alignItems={"center"}
              md={8}
              xs={6}
            >
              <Grid className={"navigation-brand"} item>
                <a href={MARKETING_SITE}>
                  <img
                    className={"beatdapp-icon"}
                    src={IMAGE_LOGO}
                    alt={"Beatdapp Icon"}
                  />
                  <img
                    className={"beatdapp-text"}
                    src={TEXT_LOGO}
                    alt={"Beatdapp"}
                  />
                </a>
              </Grid>
              <Hidden smDown>
                <Grid
                  className={"navigation-item"}
                  container
                  item
                  justifyContent={"space-evenly"}
                  md={6}
                >
                  <Grid item>
                    <HeaderLink
                      href={MARKETING_SITE + "/dsps-otts"}
                      text={"DSPs & OTTs"}
                    />
                  </Grid>
                  <Grid item>
                    <HeaderLink
                      href={MARKETING_SITE + "/labels"}
                      text={"Labels"}
                    />
                  </Grid>
                  <Grid item>
                    <HeaderLink href={BLOG_LINK} text={"Blog"} />
                  </Grid>
                  <Grid item>
                    <HeaderLink
                      href={MARKETING_SITE + "/press"}
                      text={"Press"}
                    />
                  </Grid>
                </Grid>
              </Hidden>
            </Grid>
            <Hidden smDown>
              <Grid container item justifyContent={"flex-end"} md={4}>
                <div className={"navigation-buttons"}>
                  <div className={"navigation-button"}>
                    <Grid item className={classes.loginButton}>
                      <HeaderLink
                        link={"/login"}
                        text={"Login"}
                        isActive={page === "login"}
                      />
                    </Grid>
                  </div>
                  <div className={"navigation-button"}>
                    <a href={MARKETING_SITE + "/request-demo"}>
                      <Button
                        className={"request-demo-button"}
                        variant={"contained"}
                        color={"primary"}
                      >
                        Request a Demo
                      </Button>
                    </a>
                  </div>
                </div>
              </Grid>
            </Hidden>
            <Hidden mdUp>
              <Grid container item justifyContent={"flex-end"} xs={6}>
                <Grid item>
                  <div
                    className={"drawer-menu-icon"}
                    src={
                      this.state.drawerOpen
                        ? CLOSE_MENU_ICON
                        : HAMBURGER_MENU_ICON
                    }
                    alt={"Drawer menu"}
                    style={{
                      backgroundImage: `url("${
                        this.state.drawerOpen
                          ? CLOSE_MENU_ICON
                          : HAMBURGER_MENU_ICON
                      }")`,
                    }}
                    onClick={() => {
                      this.openDrawer(!this.state.drawerOpen);
                    }}
                  ></div>
                </Grid>
              </Grid>
            </Hidden>
            <MarketingDrawer
              isOpen={this.state.drawerOpen}
              isMounted={this.state.isMounted}
              toggleDrawer={this.openDrawer}
            />
          </Grid>
        </Toolbar>
      </AppBar>
    );
  }
}

export default withStyles(overrideStyles)(MarketingNavigation);
