import React, { Component } from "react";
import "./social-icon.scss";

class SocialIcon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hovered: false,
    };
  }

  handleHover = (isHovered) => {
    this.setState({ hovered: isHovered });
  };

  render() {
    return (
      <a href={this.props.link}>
        <img
          className={"social-entry"}
          src={this.state.hovered ? this.props.hover : this.props.icon}
          alt={this.props.alt}
          onMouseEnter={() => this.handleHover(true)}
          onMouseLeave={() => this.handleHover(false)}
        />
      </a>
    );
  }
}

export default SocialIcon;
