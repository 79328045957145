import store from "../store";
import axios from "../config/axios";

class Auth {
  /**
   * Handles user sign out logic.
   */
  static signOut = async () => {
    try {
      // retrieve current region filter from redux
      const currentRegionFilter = store.getState().filter.currentFilter;

      // convert sets into arrays before storing
      let currentFilterCopy = {};
      Object.keys(currentRegionFilter).forEach((continent) => {
        currentFilterCopy[continent] = [...currentRegionFilter[continent]];
      });

      const response = await axios.post("/logout", {
        currentRegionFilter: currentFilterCopy,
      });

      if (response.status === 200) {
        window.localStorage.setItem("loggedIn", false);
        window.location.href = "/";
      }
    } catch (error) {
      // treat the user as logged out anyways to avoid lock in
      window.localStorage.setItem("loggedIn", false);
      window.location.href = "/";
    }
  };
}

export default Auth;
